<template>
  <el-main v-if="info.id">
    <div class="order-info">
      <div class="title">订单信息</div>
      <div class="order-content">
        <div class="order-title">
          <p>
            订单编号：
            <span>{{ info.pay_sn }}</span>
          </p>
          <p>
            提交时间：
            <span>{{ info.create_time }}</span>
          </p>
        </div>
        <div class="order-process">
          <div class="item" style="background: #ddddfd">
            <div class="num" style="background: #409eff; color: #fff">1</div>
            <p class="handle-text" style="color: #409eff">下单时间</p>
            <p class="handle-time">{{ info.create_time }}</p>
          </div>
          <div class="line" v-if="info.state == 20"></div>
          <div class="item" v-if="info.state == 20" :style="{ background: info.payment_time ? '#ddddfd' : '' }">
            <div class="num" :style="{ background: info.payment_time ? '#409EFF' : '', color: info.payment_time ? '#fff' : '' }">2</div>
            <p class="handle-text" :style="{ color: info.payment_time ? '#409EFF' : '' }">支付时间</p>
            <p class="handle-time" v-if="info.payment_time">{{ info.payment_time }}</p>
          </div>
          <div class="line" v-if="info.state == 40"></div>
          <div class="item" v-if="info.state == 40" :style="{ background: info.finnshed_time ? '#ddddfd' : '' }">
            <div class="num" :style="{ background: info.finnshed_time ? '#409EFF' : '', color: info.finnshed_time ? '#fff' : '' }">3</div>
            <p class="handle-text" :style="{ color: info.finnshed_time ? '#409EFF' : '' }">完成时间</p>
            <p class="handle-time" v-if="info.finnshed_time">{{ info.finnshed_time }}</p>
          </div>
          <div class="line" v-if="info.state == 0"></div>
          <div class="item" v-if="info.state == 0" :style="{ background: info.cancel_time ? '#ddddfd' : '' }">
            <div class="num" :style="{ background: info.cancel_time ? '#409EFF' : '', color: info.cancel_time ? '#fff' : '' }">3</div>
            <p class="handle-text" :style="{ color: info.cancel_time ? '#409EFF' : '' }">取消时间</p>
            <p class="handle-time" v-if="info.cancel_time">{{ info.cancel_time }}</p>
          </div>
        </div>
        <div class="order-status">
          <div class="status">
            订单状态：
            {{ info.state == 0 ? '已取消' : info.state == 10 ? '待支付' : info.state == 20 ? '已支付' : info.state == 40 ? '已完成' : '' }}
          </div>
        </div>
      </div>
    </div>
    <div class="service-list" v-if="info.order_type !=3">
      <div class="title">预约人信息</div>
      <table>
        <thead>
          <tr>
            <th>服务主图</th>
            <th>服务名称</th>
            <th>规格</th>
            <th>单价</th>
            <th>数量</th>
            <th>服务人员</th>
            <th>支付方式</th>
            <th>小计</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in info.extend_order_goods" :key="index">
            <td class="info">
              <el-image style="width: 50px; height: 50px" :src="item.goods_img"></el-image>
            </td>
            <td>{{ item.goods_title }}</td>
            <td>{{ item.sku_info ? item.sku_info.title : '--' }}</td>
            <td>¥{{ item.goods_price }}</td>
            <td>{{ item.goods_num }}</td>
            <td>{{ info.extend_appoint.staff_info.service_name }}</td>
            <td>{{ info.order_type ? '到店支付' : '在线支付' }}</td>
            <td>¥{{ Number(item.goods_price) * item.goods_num }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="goods-list" v-else>
      <div class="title">商品信息</div>
       <table>
        <thead>
          <tr>
            <th>套餐主图</th>
            <th>套餐名称</th>
            <th>套餐类型</th>
            <th>套餐单价</th>
            <th>数量</th>
            <!-- <th>次数</th> -->
            <th>小计</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in info.extend_order_goods" :key="index">
            <td class="info">
              <el-image style="width: 50px; height: 50px" :src="item.goods_img"></el-image>
            </td>
            <td>{{ item.goods_title }}</td>
            <td>{{item.goods_type == 4 && item.goods_data ? '搭配套餐' : '固定套餐'}}</td>
            <td>¥{{ item.goods_price }}</td>
            <td>{{ item.goods_num }}</td>
            <!-- <td>{{ info.extend_appoint.staff_info.service_name }}</td> -->
            <td>¥{{ item.goods_price }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
export default {
  data() {
    return {
      info: {},
      progress_status1: 0,
      progress_status2: 0,
      progress_status3: 0,
      progress_status4: 0,
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    getInfo() {
      this.$axios
        .post(this.$api.beauty.orderInfo, {
          id: this.$route.query.id,
        })
        .then(res => {
          if (res.code == 0) {
            let info = res.result.info;
            let persons_label = [];
            let persons_data = [];
            if (info.extend_appoint.order_persons) {
              info.extend_appoint.order_persons.unshift({
                name: info.extend_appoint.name,
                mobile: info.extend_appoint.mobile,
              });
            } else {
              info.extend_appoint.order_persons = [
                {
                  name: info.extend_appoint.name,
                  mobile: info.extend_appoint.mobile,
                },
              ];
            }
            info.extend_appoint.order_persons.map((item, index) => {
              for (let i in item) {
                persons_data.push(item[i]);
                if (i == 'name') {
                  persons_label.push('预约人' + (index + 1));
                } else {
                  persons_label.push('预约人电话' + (index + 1));
                }
              }
            });
            info.persons_label = persons_label;
            info.persons_data = persons_data;
            this.info = info;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  font-size: 14px;
  table {
    width: 100%;
    border: 1px solid #f8f9fa;
    margin-bottom: 20px;
    thead {
      background: #f8f9fa;
      tr th {
        padding: 15px;
        text-align: center;
      }
    }
    tbody {
      tr td {
        padding: 15px;
        text-align: center;
      }
    }
  }
  .title {
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    margin-bottom: 20px;
  }
  .order-info {
    margin-bottom: 20px;
    .order-content {
      .order-title {
        display: flex;
        height: 47px;
        background: #f0f0f5;
        align-items: center;
        padding-left: 24px;
        p {
          font-size: 14px;
          margin-right: 40px;
          span {
            font-weight: 400;
            color: #17171a;
          }
        }
      }
    }
    .order-process {
      display: flex;
      justify-content: center;
      height: 190px;
      align-items: center;
      .line {
        height: 4px;
        width: 120px;
        background: #f0f0f5;
      }
      .item {
        background: #fff;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .num {
          width: 32px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          background: #c8c8cc;
          border-radius: 50%;
          color: #ffffff;
          font-size: 14px;
          font-weight: bold;
        }
        .handle-text {
          width: 100px;
          text-align: center;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: -25px;
          font-size: 14px;
          font-weight: bold;
          color: #969699;
        }
        .handle-time {
          width: 120px;
          position: absolute;
          left: 50%;
          text-align: center;
          transform: translateX(-50%);
          bottom: -25px;
          font-size: 12px;
          font-weight: 400;
          color: #969699;
        }
      }
    }
    .order-status {
      padding: 30px 0;
      background: #f0f0f5;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .status {
        font-size: 16px;
        font-weight: bold;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #969699;
      }
      .btn {
        margin-top: 18px;
      }
    }
  }
  .buyers-info {
    margin-bottom: 20px;
    .buyers-content {
      display: flex;
      p {
        margin-right: 40px;
      }
    }
  }
  .service-info {
    margin-bottom: 20px;
    table {
      .info {
        display: flex;
        align-items: center;
        .el-image {
          margin-right: 15px;
        }
      }
    }
    .img,
    .message,
    .contract {
      padding-left: 100px;
    }
    .message {
      margin-top: 20px;
    }
    .img,
    .contract {
      display: flex;
      align-items: center;
      .el-image {
        width: 50px;
        height: 50px;
        margin-right: 15px;
      }
    }
    .contract {
      margin-top: 20px;
    }
  }
  .construction-img {
    display: flex;
    .img-type {
      margin-left: 30px;
      .img {
        margin-bottom: 20px;
        display: flex;
        span {
          width: 80px;
        }
        & > div {
          p {
            margin-bottom: 15px;
          }
          .el-image {
            width: 50px;
            height: 50px;
            margin-right: 15px;
          }
        }
      }
    }
  }
}
</style>